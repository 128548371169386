const authorizations = [
	{
		id: 1,
		name: "Super admin",
	},
	{
		id: 2,
		name: "Content manager",
	},
	{
		id: 3,
		name: "User manager",
	},
	{
		id: 4,
		name: "Reservation manager",
	},
	{
		id: 5,
		name: "Default employee",
	},
];

export default authorizations;
